.BgColour {
  background: #eeeeee;
}
.Products {
  background-color: #fefefe;
  border-radius: 8px;
  margin-top: 15px;
  padding: 46px 24px 46px 24px;
}
.Features {
  margin-bottom: 0px !important;
  margin-top: 80px;
}
.Margin {
  margin-top: 30px;
}

@media (min-width: 300px) and (max-width: 575px) {
  .Features {
    margin-top: 0px;
  }
}
