.title {
    color:#fff;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding:8px 0px;

}
.titlesuccesModal {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    color: #fff;

}


.successModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:15px
   
}
@media (min-width: 300px) and (max-width: 575px) {
    .imageContianer > img {
        max-width: 15px;
    }
}