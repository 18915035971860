.ProductName {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  font-family: "gilroyNormal" !important;
  padding-top: 10px;
}
.ProductName p {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
}
.OutBox {
  cursor: pointer;
  border: 0.5px solid #e6e6e6;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05); */
  background: #e2e2e2;

  border-radius: 4px;
  margin: 0 50px 20px 50px;
}
.ProductDetails {
  display: flex;
  justify-content: space-between;
}
.PriceDetails {
  display: flex;
  margin-top: 20px;
}
.Align {
  display: flex;
  justify-content: space-between;
}
.RupeeIcon {
  margin-top: 5px;
}
.ProductImage {
  width: 100%;
  padding: 20px;
}
.ProductProperty {
  font-weight: 400;
  font-size: 14px;
  color: #757c81;
  font-family: "gilroyNormal" !important;
}
.Price {
  font-weight: 700;
  font-size: 16px;
  color: #0d1217;
  text-align: right;
}
.prodctDetBtn {
  width: 150px;
  font-family: "gilroyNormal" !important;
  font-size: 14px;
  height: 45px;
  padding-top: 13px;
  font-weight: 500;
  border-radius: 5px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background: #978958;
}
.promo {
  font-family: "gilroyNormal" !important;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #939393;
}

.Star {
  margin-left: 5px;
}
.TopText {
  height: 50px;
}
.RateAndReview {
  margin-left: 10px;
}
.Rating {
  text-align: right;
  font-weight: 600;
  font-size: 13px;
  color: #0997e7;
}
.ProductDetailsText {
  margin-top: 15px;
}
.OrderId {
  color: #757c81;
  font-size: 11px;
  font-weight: 400;
  font-family: "gilroyNormal" !important;
  margin: 10px;
  padding-top: 10px;
}
.OrderedTime {
  color: #757c81;
  font-size: 11px;
  font-weight: 400;
  font-family: "gilroyNormal" !important;
  margin: 10px;
}
.Delivered {
  color: #37b344;
  font-size: 11px;
  font-weight: 600;
  font-family: "gilroyNormal" !important;
  line-height: 0.1;
  margin-left: 10px;
}
.Flex {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}
.Promo {
  color: #757c81;
  font-size: 11px;
  font-weight: 400;
  font-family: "gilroyNormal" !important;
  line-height: 0.1;
  margin-right: -10px;
}
.Amount {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: -10px;
  color: #0d1217;
  font-weight: 700;
  font-size: 13px;
}
.Rupee {
  margin-top: 5px;
}
.Background {
  background: #ffffff;
  border-radius: 4px;
  /* padding: 25px; */
}
.TextWrapper {
  padding-top: 18px;
}
.Expected {
  color: #454647;
  font-family: "LatoRegular";
  font-size: 14px;
  font-weight: 400;
}
.GreenText {
  font-family: "LatoRegular";
  color: #30933a;
  font-size: 14px;
  font-weight: 400;
}
.BottomContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.ImageContent {
  display: flex;
  gap: 25px;
}
.ImageContent h3 {
  font-family: "LatoRegular";
  color: #0d1217;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 0px;
}
.DeliveryText {
  padding-top: 10px;
  font-family: "LatoRegular";
  color: #454647;
  font-size: 14px;
  font-weight: 400;
}
.ViewButton {
  display: flex;
  background-color: #19242d;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
}
.ViewButton button {
  border: none;
  color: #ffffff;
  text-align: center;
  background-color: #19242d;
  font-family: "LatoRegular";
  font-weight: 500;
  font-size: 14px;
}
.RighSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.RatingContainer {
  display: flex;
  align-items: center;
  gap: 30px;
}
.RatingContainer p {
  margin-bottom: 0px;
  color: #0997e7;
  font-family: "LatoRegular";
  font-size: 18px;
  font-weight: 400;
}
.OverLined {
  text-decoration: line-through;
}
.RightArrow {
  color: #ffffff;
}
.RateContainer {
  padding: 15px 5px 25px;
}
.MobileRightArrow {
  display: none;
}
@media (min-width: 300px) and (max-width: 575px) {
  .OutBox {
    margin: 0px 0px 10px;
  }
  .ViewButton {
    background-color: #ffffff;
  }
  .ViewButton {
    display: none;
  }
  .BottomContainer {
    flex-direction: column;
  }
  .ImageContent {
    gap: 10px;
  }
  .ImageContent img {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 10px;
  }
  .RighSection {
    display: block;
  }
  .TextWrapper {
    padding-top: 0px;
  }
  .RatingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px 5px;
  }
  .RatingContainer p {
    font-size: 16px;
  }
  .MobImgContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ProductName {
    padding-top: 0px;
  }
  .ProductName p {
    margin-bottom: 0px;
    padding-bottom: 5px;
  }
  .DeliveryText {
    font-size: 12px;
    padding-top: 3px;
  }
  .Expected {
    font-size: 12px;
  }
  .RateContainer {
    padding: 15px 5px 0px;
  }
  .MobileRightArrow {
    display: block;
  }
}
