.Products {
  background-color: #fefefe;
  border-radius: 8px;
  margin-top: 15px;
  padding: 46px 24px 46px 24px;
  min-height: 800px;
}
.Page {
  background: #eeeeee;
}
.PageAlign {
  justify-content: start !important;
}
/* .DownloadOurAppImage{
    margin-top: 0px !important;
} */
.NewArrivalsPage {
  margin-top: 20px;
}
@media (min-width: 300px) and (max-width: 567px) {
  .pageout {
    padding-top: 20px;
    padding-right: 0px !important;
  }
  .Page {
    background: #fff;
  }
  .Products {
    padding: 5px 24px 46px 24px;
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .mobArrivalContainer {
    padding: 0px !important;
  }
}
