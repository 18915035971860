.ImageText1 {
  font-family: "gilroyNormal" !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 10%;
  left: 27%;
  color: #fff;
  font-size: 20px;
}
.MobScreensParent {
  margin-right: 10px;
}
.ImageText2 {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #14c8dc;
  font-family: "gilroyNormal" !important;
}

.ShopOnBudgetImage {
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
}
@media screen and (max-width: 400px) {
}
@media (min-width: 768px) and (max-width: 991px) {
  .ShopOnBudgetImage {
    height: 170px;
  }
  .ImageText1 {
    position: absolute;
    bottom: 10%;
    left: 20%;
    font-size: 16px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .ShopOnBudgetImage {
    margin-bottom: 10px;
  }
}
@media (min-width: 300px) and (max-width: 575px) {
  .ShopOnBudgetImage {
    margin-bottom: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 130px;
  }
  .ImageText1 {
    color: #ffffff;

    font-family: "GilroyRegular";
    font-weight: 700;
    font-size: 14px;
    bottom: 16%;
    left: 23%;
  }
  .ImageText2 {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #14c8dc;
    font-family: "InterNormal" !important;
  }
}
