.DeliveryPin {
  display: flex;
  flex-direction: column;
}
.checkDeliveryTitle {
  color: #fff;
  font-size: 12px;
  font-family: "lato";
}
.EnterPinTitle {
  color: #00e5ed;
  font-size: 12px;
  font-family: "lato";
}
.LocationInp input::placeholder {
  font-size: 25px;
  position: relative;
  top: 10px;
}
.LocationDetails {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}
.Locationp1 {
  text-align: center;
  font-family: "lato";
  color: #00464d;
  font-weight: 600;
  font-size: 20px;
}
.Locationp2 {
  color: #757c81;
  font-size: 15.5px;
  text-align: center;
}
.LocationInp input {
  width: 100%;
  box-shadow: 0px 4px 4px 0px #0000000d inset;
  border: 1px solid #e8e9ea;
  padding: 12px 15px;
  outline: none;
  border-radius: 4px;
  background: #f9f9f9;
  color: #1e1e1e;
  font-family: "gilroyNormal";
}
.LocationInp {
  margin-bottom: 10px;
  position: relative;
}
.DeliveryBtns button {
  background: transparent;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #006e7f;
  font-family: "lato";
}
.LocationIconarrow {
  position: absolute;
  right: 0%;
  background: #00464d;
  font-size: 50px;
  padding: 10px 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.headerElement:hover {
  transform: scale(1) translateY(-2px); /* Scale up and slightly move upwards */
  transition: transform 0.3s ease; /* Add smooth transition effect */
}
