header {
  /* height: 100px; */
  height: 80px;
  padding: 10px 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Navbar {
  display: none;
}

.mobile-modal {
  position: absolute;
  top: 0;
  right: -100%; /* Initially positioned outside the viewport */
  transition: transform 0.3s ease-in-out; /* Add transition effect */

  border: none;

  overflow-y: auto;
  max-height: 100vh;
  width: 100%;
  outline: none;
  transform: translateX(100%); /* Initially translate it out of the viewport */
}

/* Apply the above styles to the modal content when it's open */
.mobile-modal.open {
  transform: translateX(0); /* Translate it back to the viewport */
}
.line {
  display: grid;
  grid-template-columns: 1fr 0.8fr 1fr;
  align-items: center;
  margin: 1rem 0rem;
  width: 100%;
}
.MobMainHead {
  background: #10212f;
  box-shadow: 0px 5px 10px 0px #0000000d;
  padding: 30px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ParentMobSection {
  background: #f8f8f8;
  height: 100vh;
}
.MobLog_Signup {
  display: grid;
  grid-template-columns: 1fr 0.4fr 1fr;
  align-items: center;
}
.MobLeftSection {
  display: flex;
  align-items: center;
  gap: 15px;
}
.ParentCards1 {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 0.5px solid #f0ebd1;
  padding: 15px 0px;
  margin: 15px;
  padding-top: 0px;
  margin-bottom: 0px;
}
.ParentCards2 {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 0.5px solid #f0ebd1;
  padding: 20px;
  margin: 20px 0px;
  padding-top: 0px;
  margin-bottom: 0px;
}
.hamMenu {
  max-width: 30px;
}
.LoggedDetailsList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-family: "GilroyRegular";
}
.ParentCards1 p {
  font-family: "lato";
  font-weight: 400;
  color: #475057;
}
.ParentCards2 p {
  font-family: "lato";
  font-weight: 400;
  color: #475057;
}
.BorderLineMob {
  width: 0.5px;
  height: 20px;
  background: #ffffff4d;
}
.ParentSearchBar {
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0%;
  top: 0%;
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  padding: 28px 20px;
  gap: 10px;
  align-items: center;
}
.MobParentSearchBars input {
  width: 100%;
  border: 0.5px solid #026f7f;
  border-radius: 32px;
  padding: 8px 35px;
  box-shadow: 1px 4px 4px 0px #00464d1a inset;
  color: #9c9c9c;
  font-family: "gilroyNormal" !important;
  outline: none;
}
.searchListCont2 {
  background: white;
  width: 100%;
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 12%;
  padding: 10px;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.SearchItem {
  color: #505050;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "gilroyNormal" !important;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: pointer;
}
.Closesearch1 {
  position: absolute;
  color: #000;
  top: 25%;
  right: 5%;
  font-size: 18px;
}
.MobParentSearchBars input::placeholder {
  font-size: 16px;
}
.MobParentSearchBars {
  position: relative;
}
.Gosearch1 {
  position: absolute;
  color: #000;
  top: 25%;
  left: 5%;
  font-size: 18px;
}
.Arrowline32 {
  font-size: 25px;
}
.MobLog_Signup p {
  color: #fff;
  font-family: "gilroyNormal" !important;
}
.signuptitletext {
  color: #0d1217;
  /* text-align: center; */
  font-family: "Playfair Display";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
}
.Signup {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 0px 0px 0px;
}
.LoginButton {
  background-color: #00464d;
  width: 100%;
  height: 2.45456rem;
  color: #fff;
  margin-bottom: 0rem;
  border-radius: 8px;
  border: none;
}
.mobileLogo {
  max-width: 42px;
}
.acceptT {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00464d;
  width: 100%;
  height: 2.45456rem;
  color: #fff;
  border: none;
  border-radius: 8px;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.Error {
  animation: shake 0.2s ease-in-out 0s 2;
  border: 1px solid #ff0000;
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;

  font-weight: 400;
  outline: none;
  border-radius: 5px;
  padding: 8px 10px;
}
.Error::placeholder {
  color: #ff0000;
}

.ErrorText {
  margin-bottom: 0px;
  padding-top: 2px;
  color: #ff0000;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
}

@media (min-width: 300px) and (max-width: 575px) {
  .loginToffle {
    max-height: 475px;
    max-height: 560px;
    overflow-y: scroll !important;
  }
  .loginWrapper {
    padding: 15px 5px;
  }
  .loginToffle {
    padding: 10px 10px;
  }
  .CountryFlags {
    position: relative;
    z-index: 1;
  }
  .CountryDropDowns {
    position: absolute;
    background-color: #d1d1d1;
    border-radius: 4px;
    margin-top: 10px;
    z-index: 1;
  }
  .contryelements {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px 30px 5px 10px;
  }
  .dropDownImages {
    border-radius: 50%;
  }
  .contryelements img {
    border-radius: 35%;
    font-size: 18px;
    width: 30px;
    height: 30px;
  }
  .selectedImage {
    width: 30px !important;
    border-radius: 50%;
    width: 27px !important;
    border-radius: 50%;
    height: 27px !important;
  }

  .CountryContainer {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
  }
  .CountryContainer:hover {
    background: #ffffff80;
    border-radius: 4px;
    z-index: 999999999;
  }
  /* .CountryContainer :hover {
  background-color: #FFFFFF80;
} */
  .CountryContainer span {
    color: #000000;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  .Navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    background-color: #fff;
    /* text-align: center; */
  }
  .NavElements {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: end;
  }
  .LeftIcons {
    display: flex;
  }
  .LeftIcons > Hamburger {
    margin-top: 10px;
  }
  .rightIcons {
    display: flex;
    gap: 12px;
  }
  .LoginContainer {
    /* padding: 1rem 1rem 1.5rem 1rem; */
    margin-bottom: 1rem;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  .title {
    display: flex;
    /* grid-template-rows: 1fr 1fr; */
    /* justify-items: center; */
    align-items: center;
    flex-direction: column;
    padding: 15px 0px;
    /* padding-top: 5px; */
  }
  .titleh {
    color: #0d1217;
    text-align: center;
    font-family: "PlayFairNormal";
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .titlep {
    color: #0d1217;
    text-align: center;
    font-family: "gilroyNormal";
    font-style: normal;
    font-weight: 400;
    /* line-height: 1.625rem; */
    opacity: 0.7;
    font-size: 14px;
  }
  .SocialButtons {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }
  .soButton {
    gap: 5px;
    display: flex;
  }
  .Wrapper {
    padding: 0rem 0rem;
  }
  .soButton > div {
    gap: 2rem;
  }
  .socialimg {
    gap: 10px;
  }
  .socialtext {
    gap: 10px;
  }

  .LoginContainer .line {
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    align-items: center;
    margin: 12px 0px;
    width: 100%;
  }
  .orText {
    font-size: 12px;
    text-align: center;
  }
  .TabButton {
    background-color: #f0f0f2;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-top: 0.5rem;
    padding: 2px 2px;
  }
  .tabHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* grid-template-columns: 1fr 1fr; */
    /* width: 100%; */

    place-items: center;
  }
  /* .tabItems {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  .active {
    border-radius: 0.25rem;

    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .Signup {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .signupAnchor {
    color: #006e7f;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    border: none;
    font-family: "gilroyNormal";
    background-color: transparent;
    padding: 0px 2px;
    cursor: pointer !important;
  }
  .bottomText {
    color: #585f67;
    text-align: center;
    font-family: "gilroyNormal";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  /* .bottomText :nth-of-type(2) {
    color: #0d1217;
    text-align: center;
    font-family: Gilroy;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
  } */
  .acceptT {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00464d;
    width: 100%;
    height: 2.45456rem;
    color: #fff;
  }
  .accept {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00464d;
    width: 100%;
    height: 2.45456rem;
    color: #fff;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    border-radius: 8px;
    border: none;
  }

  /* Signup styles start here */

  .SignupWrapper {
    /* margin-bottom: 0.5rem; */
    padding: 20px 0px;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  .signupContainer {
  }
  .signuptitletext {
    color: #0d1217;
    /* text-align: center; */
    font-family: "Playfair Display";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  /* .signupsecondtitle {
    color: #0d1217;
    text-align: center;
    font-family: Lato;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
  } */
  .headerTitle {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  }
  .formInputFields {
    place-items: center;
  }
  .line2 {
    /* display:flex */
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    align-items: center;
    margin: 12px 0px;
    width: 100%;
  }
  .buttonSocial {
    border: 1px solid #ccc;
    font-size: 0.875rem;
    background-color: transparent;
    padding: 5px 6px;
    color: #2c3035;
    /* text-align: center; */
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    text-align: center;
    /* width: 100%; */
  }
  .buttonSocial > img {
    max-width: 14px;
    margin: 0px 6px 2px 2px;
  }
  .tabTitleOne {
    /* padding: 9px 20px; */
    padding: 10px 31px;
    font-size: 14px;
    color: #111212;
    text-align: center;
    font-family: "gilroyNormal";
    /* font-size: 1rem; */
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    cursor: pointer;
  }
  .tabTitleTwo {
    padding: 10px 61px;
    text-align: center;
    font-size: 14px;
    color: #111212;
    text-align: center;
    font-family: "gilroyNormal";
    /* font-size: 1rem; */
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .loginFormInput {
    width: 100%;
    color: #c1cbcd;
  }
  .labelStyle {
    color: black;
    font-family: "gilroyNormal";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .allInputTextStyle {
    width: 100%;
    border: 1px solid lightgray;
    font-family: "Poppins";
    font-size: 14px;

    font-weight: 400;
    outline: none;
    border-radius: 5px;
    padding: 8px 10px;
  }
  .allInputTextStyle ::placeholder {
    opacity: 0.5;
    font-size: 1rem;
  }
  .inputformContainer {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
  }
  .signupTitleText {
  }
  .signupTitleText > p {
    text-align: center;
    font-size: 14px;
  }
  .otpContainer {
    padding: 5px 15px;
    margin: 10px 10px;
  }
  .formgap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .loginWrapper {
    padding: 15px 5px;
  }
  .Navbar {
    display: block;
    background-color: #fff;
  }
  .NavElements {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .rightIcons {
    display: flex;
    gap: 2rem;
  }
  .SlideButton {
    background-color: #fff;
  }

  .LoginButton {
    background-color: #00464d;
    width: 100%;
    height: 3rem;
    color: #fff;
    border-radius: 8px;
    border: none;
  }

  .title {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .titleh {
    color: #0d1217;
    text-align: center;
    font-family: "PlayFairNormal";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 2rem;
  }
  .titlep {
    color: #0d1217;
    text-align: center;
    font-family: "gilroyNormal";
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    opacity: 0.7;
  }
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0rem;
  }
  .SocialButtons {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    /* margin-bottom: 1.5rem; */
  }
  .buttonSocial {
    border: 1px solid #ccc;
    font-size: 0.875rem;
    background-color: transparent;
    padding: 9px 15px;
    color: #2c3035;
    /* text-align: center; */
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
  }
  .buttonSocial > img {
    margin-right: 25px;
    max-width: 21px;
  }
  .TabButton {
    background-color: #f0f0f2;
    border-radius: 0.5rem;
    margin: 2rem 0rem;
    margin-top: 0.5rem;
  }
  .tabHeader {
    display: flex;

    width: 100%;

    place-items: center;
    margin-top: 2rem;
  }
  .active {
    border-radius: 0.25rem;
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
  }

  .LoginContainer .line {
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    align-items: center;
    margin: 1rem 0rem;
    width: 100%;
  }
  .orText {
    font-size: 15px;
    text-align: center;
    /* line-height: 10px; */
  }
  .line2 {
    /* display:flex */
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    align-items: center;
    margin: 1rem 0px;
    width: 100%;
  }
  /* .loginFormInput {
    width: 100%;
    color: #c1cbcd;
  } */

  .labelStyle {
    color: black;
    font-family: "gilroyNormal";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .allInputTextStyle {
    width: 100%;
    border: 1px solid lightgray;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 400;
    outline: none;
    border-radius: 5px;
    padding: 8px 12px;
    line-height: 1.5rem;
  }
  .tabContent {
    /* padding: 0px 15px; */
    margin: 1rem 0rem;
  }
  .tabTitleOne {
    /* padding: 9px 20px; */
    padding: 15px 59px;
    font-size: 15px;
    color: #111212;
    text-align: center;
    font-family: "gilroyNormal";
    /* font-size: 1rem; */
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .tabTitleTwo {
    padding: 15px 50px;
    text-align: center;
    font-size: 15px;
    color: #111212;
    text-align: center;
    font-family: "gilroyNormal";
    /* font-size: 1rem; */
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .LoginButton {
    background-color: #00464d;
    width: 100%;
    height: 3rem;
    color: #fff;
    margin: 0.5rem 0rem;
    font-size: 16px;
    border-radius: 8px;
    border: none;
  }
  .Signup {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem 0rem;
  }
  .signupAnchor {
    color: #006e7f;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    border: none;
    padding: 0px;
    margin: 0px;
    font-family: "gilroyNormal";
    background-color: transparent;
    padding: 0px 4px;
    cursor: pointer;
  }
  .bottomText {
    color: #585f67;
    text-align: center;
    font-family: "gilroyNormal";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .otpContainer {
    padding: 5px 15px;
    margin: 1rem 1rem;
  }
  .titleh {
    color: #0d1217;
    text-align: center;
    font-family: "PlayFairNormal";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .titlep {
    color: #0d1217;
    text-align: center;
    font-family: "gilroyNormal";
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    opacity: 0.7;
    font-size: 16;
  }

  .accept {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00464d;
    width: 100%;
    height: 3rem;
    color: #fff;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 16px;
    border-radius: 8px;
    border: none;
  }
  .acceptT {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00464d;
    width: 100%;
    height: 2.45456rem;
    color: #fff;
  }
  .signuptitletext {
    color: #0d1217;
    /* text-align: center; */
    font-family: "Playfair Display";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .signupsecondtitle {
    color: #0d1217;
    text-align: center;
    font-family: "gilroyNormal";
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    opacity: 0.7;
  }
  .headerTitle {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;
  }
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .formInputFields {
    place-items: center;
  }
  .line2 {
    /* display:flex */
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    align-items: center;
    margin: 12px 0px;
    width: 100%;
  }
  .SignupWrapper {
    padding: 4px;
  }
  .SocialButtons {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }
  .signupInputFields {
    margin-bottom: 1rem;
  }
  .selectedImage {
    width: 30px !important;
    border-radius: 50%;
    width: 27px !important;
    border-radius: 50%;
    height: 27px !important;
  }
  .formgap {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

@media (min-width: 767px) and (max-width: 4200px) {
  .NavContainer {
    display: none;
  }
  .loginWrapper {
    padding: 0px 0px;
  }
  .Navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    background-color: #fff;
    /* text-align: center; */
  }
  .NavElements {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .LeftIcons {
    display: flex;
  }
  .LeftIcons > Hamburger {
    margin-top: 10px;
  }
  .rightIcons {
    display: flex;
    gap: 2rem;
  }
  .LoginContainer {
    /* padding: 1rem 1rem 1.5rem 1rem; */
    margin-bottom: 1rem;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  .title {
    display: flex;
    /* grid-template-rows: 1fr 1fr; */
    /* justify-items: center; */
    align-items: center;
    flex-direction: column;
    padding-bottom: 5px;
    /* padding-top: 5px; */
  }
  .titleh {
    color: #0d1217;
    text-align: center;
    font-family: "PlayFairNormal";
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .titlep {
    color: #0d1217;
    text-align: center;
    font-family: "gilroyNormal";
    font-style: normal;
    font-weight: 400;
    /* line-height: 1.625rem; */
    opacity: 0.7;
    font-size: 14px;
  }
  .SocialButtons {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }
  .soButton {
    gap: 5px;
    display: flex;
  }
  .Wrapper {
    padding: 0rem 0rem;
  }
  .soButton > div {
    gap: 2rem;
  }
  .socialimg {
    gap: 10px;
  }
  .socialtext {
    gap: 10px;
  }

  .LoginContainer .line {
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    align-items: center;
    margin: 12px 0px;
    width: 100%;
  }
  .orText {
    font-size: 15px;
    text-align: center;
  }
  .TabButton {
    background-color: #f0f0f2;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    padding: 2px 2px;
  }
  .tabHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* grid-template-columns: 1fr 1fr; */
    /* width: 100%; */

    place-items: center;
  }
  /* .tabItems {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  .active {
    border-radius: 0.25rem;

    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .LoginButton {
    background-color: #00464d;
    width: 100%;
    height: 2.45456rem;
    color: #fff;
    border-radius: 8px;
    border: none;
  }
  .SignupTextWrapper {
    display: flex;
    flex-direction: column;
  }
  .Signup {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .signupAnchor {
    color: #006e7f;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    border: none;
    font-family: "gilroyNormal";
    background-color: transparent;
    padding: 0px 2px;
    cursor: pointer;
  }
  .bottomText {
    color: #585f67;
    text-align: center;
    font-family: "gilroyNormal";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  /* .bottomText :nth-of-type(2) {
    color: #0d1217;
    text-align: center;
    font-family: Gilroy;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
  } */
  .accept {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00464d;
    width: 100%;
    height: 2.45456rem;
    color: #fff;
    margin-bottom: 5px;
    margin-top: 15px;
    border-radius: 8px;
    border: none;
  }
  .acceptT {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00464d;
    width: 100%;
    height: 2.45456rem;
    color: #fff;
  }

  /* Signup styles start here */

  .SignupWrapper {
    /* margin-bottom: 0.5rem; */
    padding: 0px 0px;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
  }
  .signupContainer {
  }
  .signuptitletext {
    color: #0d1217;
    /* text-align: center; */
    font-family: "Playfair Display";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  /* .signupsecondtitle {
    color: #0d1217;
    text-align: center;
    font-family: Lato;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
  } */
  .headerTitle {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;
  }
  .formInputFields {
    place-items: center;
  }
  .line2 {
    /* display:flex */
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    align-items: center;
    margin: 5px 0px;
    width: 100%;
  }
  .buttonSocial {
    border: 1px solid #ccc;
    font-size: 0.875rem;
    background-color: transparent;
    padding: 5px 3px !important;
    color: #2c3035;
    /* text-align: center; */
    font-family: "Poppins";
    font-size: 13.5px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    /* width: 100%; */
  }
  .buttonSocial > img {
    max-width: 13px;
    margin: 0px 4px 3px 0px;
  }
  .modalContainer:focus-visible {
    border: none;
    outline: none;
  }
  .tabTitleOne {
    /* padding: 9px 20px; */
    padding: 10px 30px;
    font-size: 14px;
    color: #111212;
    text-align: center;
    font-family: "gilroyNormal";
    /* font-size: 1rem; */
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .tabTitleTwo {
    padding: 10px 68px;
    text-align: center;
    font-size: 14px;
    color: #111212;
    text-align: center;
    font-family: "gilroyNormal";
    /* font-size: 1rem; */
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .loginFormInput {
    width: 100%;
    color: #c1cbcd;
  }
  .labelStyle {
    color: black;
    font-family: "gilroyNormal";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .allInputTextStyle {
    width: 100%;
    border: 1px solid lightgray;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border-radius: 5px;
    padding: 8px 10px;
  }
  .allInputTextStyle ::placeholder {
    opacity: 0.5;
    font-size: 1rem;
  }
  .inputformContainer {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
  }
  .signupTitleText {
  }
  .signupTitleText > p {
    text-align: center;
    font-size: 14px;
  }
  .otpContainer {
    margin: 6px 0px;
  }
  .formgap {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.slideRight {
  position: absolute;
  left: -150;
  -webkit-animation: slideRight 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slideRight 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
