.Image {
  width: 100%;
}
.Video {
  width: 100%;
}
.Video iframe {
  height: 323px !important;
}
.Certificates {
  margin-top: 25px;
}
.YTVideo {
  width: 100%;
  /* height: 100%; */
  height: 378px;
}
@media screen and (max-width: 1200px) {
  .Certificates {
    margin-top: 10px;
  }
  .YTVideo {
    height: 269px;
  }
}
@media screen and (max-width: 991px) {
  .Certificates {
    margin-top: 15px;
  }
  .YTVideo {
    height: 196px;
  }
}
@media screen and (max-width: 767px) {
  .Certificates {
    margin-top: 30px;
  }
  .YTVideo {
    margin-top: 25px;
    height: 292px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Certificates {
    margin-top: 30px;
  }
}

@media (min-width: 1400px) and (max-width: 3000px) {
  .Video iframe {
    height: 378px !important;
  }
}
