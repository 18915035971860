.Title {
    font-weight: 600;
    font-size: 24px;
    font-family: 'PlayFairNormal' !important;
}

.Main {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin:30px 50px 0 50px;
    /* border-bottom: 0.5px solid #E8E9EA; */
}
.Home {
    color: #00464D;
    font-size: 14px;
    font-family: 'gilroyNormal' !important;
    font-weight: 500;

}
.NewArrival {
    color: #01B3C5;
    font-size: 14px;
    font-family: 'gilroyNormal' !important;
    font-weight: 500;
}
.SubText {
    display: flex;

    font-size: 14px;
    font-family: 'gilroyNormal' !important;

}
.DropDown{
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 40px;
}
.accordion-button{
    background: #ffffff !important;
    border: none !important;
    font-family: 'gilroyNormal' !important;
}
.accordion-header:focus{
    background: #ffffff !important;
    
    border: none !important;
    font-family: 'gilroyNormal' !important;
}
.Question{
    font-family: 'gilroyNormal' !important;
    font-weight: 600;
font-size: 16px;
border: none;
color: #0D1217;

}
.faqTextContainer {
    display: flex;
    flex-direction: column;
    gap:15px;
    padding:20px

}
.Answer{
    color: #757C81;
    font-weight: 400;
font-size: 14px;
}




@media screen and (max-width:500px) {
.Main {
    margin:20px 10px 0 10px;
}
.DropDown{
   
    margin-left: 10px;
}
}

@media (min-width: 575px) and (max-width: 767px) {
    
}
@media (min-width: 300px) and (max-width: 575px) {
    .Question{
        font-family: 'gilroyNormal' !important;
        font-weight: 600;
    font-size: 16px;
    border: none;
    color: #0D1217;
    padding-right: 10px;
    }
   
    .DropDown{
   
        margin:15px,0px;
    }
    .faqTextContainer {
        display: flex;
        flex-direction: column;
        gap:15px;
        padding:0px
    
    }
 

}