.Title {
    font-weight: 600;
    font-size: 24px;
    font-family: 'PlayFairNormal' !important;
}

.Main {
    display: flex;
    margin-top: 30px;

}

.SubText {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    font-family: 'gilroyNormal' !important;
    border-bottom: 0.5px solid #E8E9EA;
    padding-bottom: 35px;

}
.Content {
 
}

.Home {
    color: #00464D;
    font-weight: 400;
    font-size: 14px;
    font-family: 'gilroyNormal' !important;

}

.PrivacyPolicy {
    color: #01B3C5;
    font-weight: 400;
    font-size: 14px;
    font-family: 'gilroyNormal' !important;

}

.Description {
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #757C81;
    margin-top: 25px;
    font-family: 'gilroyNormal' !important;
}

.SubHeading {
    font-weight: 600;
    font-size: 18px;
    color: #007481;
    font-family: 'gilroyNormal' !important;
    padding:10px 0px
    
}
ul ul {
    list-style-type:disc; /* or any other desired list-style-type */
  }


@media (min-width: 300px) and (max-width: 575px) {
.Main {
    display: flex;
    margin-top:20px
}
.Title {
    font-weight: 600;
    font-size: 20px;
    font-family: 'PlayFairNormal' !important;
    padding-bottom: 5px;
}
.Home {
    color: #00464D;
    font-weight: 400;
    font-size: 14px;
    font-family: 'gilroyNormal' !important;

}
.ulForDesk {
  
}
.PrivacyPolicy {
    color: #01B3C5;
    font-weight: 400;
    font-size: 14px;
    font-family: 'gilroyNormal' !important;

}
.temsContainer {
    padding:0px 0px
}
.Description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #757C81;
    margin: 8px 0px;
    font-family: 'gilroyNormal' !important;
}
.SubHeading {
    font-weight: 600;
    font-size: 13px;
    color: #007481;
    font-family: 'gilroyNormal' !important;
    padding:0px 0px;
}
.SubText {
    padding-bottom:30px ;
}
}